import React, { lazy } from 'react'

const BusinessInsightManagementListingV2 = lazy(() =>
  import('./BusinessInsightManagement/BusinessInsightManagementListingV2').then(
    module => ({
      default: module.BusinessInsightManagementListingV2,
    })
  )
)

const OrgStructure = lazy(() =>
  import('./OrgStructure/SystemAdmin/OrgStructure').then(module => ({
    default: module.OrgStructure,
  }))
)

const OrgStructureChart = lazy(() =>
  import('./OrgStructure/OrgStructureChart').then(module => ({
    default: module.OrgStructureChart,
  }))
)

const OrgStructureSetListing = lazy(() =>
  import(
    './OrgStructure/SystemAdmin/OrgStructureSet/OrgStructureSetListing'
  ).then(module => ({
    default: module.OrgStructureSetListing,
  }))
)

const OrgStructureSetForm = lazy(() =>
  import('./OrgStructure/SystemAdmin/OrgStructureSet/OrgStructureSetForm').then(
    module => ({
      default: module.OrgStructureSetForm,
    })
  )
)

const OrgStructureSetupListing = lazy(() =>
  import(
    './OrgStructure/SystemAdmin/OrgStructureSetup/OrgStructureSetupListing'
  ).then(module => ({
    default: module.OrgStructureSetupListing,
  }))
)

const OrgStructureSetupForm = lazy(() =>
  import(
    './OrgStructure/SystemAdmin/OrgStructureSetup/OrgStructureSetupForm'
  ).then(module => ({
    default: module.OrgStructureSetupForm,
  }))
)

const HomeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <BusinessInsightManagementListingV2 />,
  },
  {
    props: { exact: true, path: '/BusinessInsightManagementListingV2' },
    component: <BusinessInsightManagementListingV2 />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/OrgStructure',
    },
    component: <OrgStructure />,
  },

  {
    props: { exact: true, path: '/OrgStructureChart' },
    component: <OrgStructureChart />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/OrgStructure/OrgStructureSetListing',
    },
    component: <OrgStructureSetListing />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/OrgStructure/OrgStructureSetForm',
    },
    component: <OrgStructureSetForm />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/OrgStructure/OrgStructureSetupListing',
    },
    component: <OrgStructureSetupListing />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/OrgStructure/OrgStructureSetupForm',
    },
    component: <OrgStructureSetupForm />,
  },
]

export default HomeRoutes
